<template>
  <div>
    <b-modal
      size="xl"
      id="modal_select_price"
      title="Seleccione o ingrese precios"
      ok-only
    >
      <div class="row">
        <div class="col-md-4" v-for="item in posPrices" :key="item.id">
          <div
            class="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7"
            bis_skin_checked="1"
          >
            <span
              class="svg-icon svg-icon-3x svg-icon-warning d-block my-2 text-warning"
              >{{ item.fuelQuantity.toFixed(3) }}</span
            ><a
              href="#"
              class="text-warning font-weight-bold"
              style="font-size: 40px"
            >
              {{ item.fuelPrice }}
            </a>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7"
            bis_skin_checked="1"
            style="cursor: pointer"
            @click="setNewPrice()"
          >
            <span class="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
              <b-icon
                icon="plus-circle"
                style="color: #ffa800 !important"
              ></b-icon> </span
            ><a href="#" class="text-warning font-weight-bold font-size-h6">
              Definir venta rápida
            </a>
          </div>
        </div>
      </div>
    </b-modal>
    <portal to="subheader_portal">
      <router-link :to="{ name: 'fuel_dispenser_create' }">
        <b-button variant="success"
          ><b-icon icon="plus" aria-hidden="true"></b-icon> Nuevo
          surtidor</b-button
        >
      </router-link>
    </portal>
    <div class="row">
      <div class="col-md-7">
        <b-card>
          <b-card-text>
            <div class="row">
              <div v-for="item in pumps" :key="item.id" class="col-md-4">
                <b-card
                  class="text-center"
                  :style="{ backgroundColor: item.fuel.hex_color }"
                >
                  <b-card-text>
                    <div
                      class="font-weight-bolder font-size-h3"
                      :style="{
                        backgroundColor: item.fuel.hex_color,
                        textAlign: 'right',
                        color: getContrastColor(item.fuel.hex_color),
                      }"
                    >
                      S/ {{ item.fuel.sale_unit_price }}
                    </div>
                    <div
                      :style="{
                        fontSize: '120px',
                        fontWeight: 'bold',
                        textAlign: 'right',
                        paddingRight: '0px',
                        color: getContrastColor(item.fuel.hex_color),
                      }"
                    >
                      {{ item.fuel.internal_id }}
                    </div>
                    <div
                      @click="openSetPosPriceModal(item.fuel)"
                      class="font-weight-bolder font-size-h1"
                      :style="{
                        backgroundColor: item.fuel.hex_color,
                        textAlign: 'right',
                        color: getContrastColor(item.fuel.hex_color),
                        cursor: 'pointer',
                      }"
                    >
                      <b-icon icon="cart3"></b-icon></div
                  ></b-card-text>
                </b-card>
              </div>
            </div>
          </b-card-text>
        </b-card>
      </div>
      <div class="col-md-5">
        <b-card>
          <b-card-text></b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Utils from "@/core/utils/utils";

export default {
  name: "LogisticsDispatchList",
  data() {
    return {
      invoice: null,
      form: {
        start_date: null,
        end_date: null,
      },
      getContrastColor: Utils.getContrastColor,
      page: 1,
      rows: 0,
      perPage: 10,
      fields: [
        { key: "figure", label: "" },
        { key: "serie", label: "Serie" },
        { key: "number", label: "Número" },
        { key: "customer", label: "Cliente" },
        { key: "delivery_status", label: "Delivery" },
        { key: "total", label: "Total" },
        { key: "actions", label: "acciones" },
      ],
      pumps: [],
      posPrices: [],
      selectedFuel: null,
    };
  },
  methods: {
    editItem(item) {
      this.$router.push({
        name: `fuel_dispenser_edit`,
        params: { id: item.id },
      });
    },
    getPumps() {
      ApiService.get(
        `fuel/pump`,
        `?page_size=99999999&page=1&dispenser=${this.$route.params.id}`
      ).then((res) => {
        this.pumps = res.data.results;
      });
    },
    generateExcel() {
      const api_url = localStorage.getItem("API_URL");
      const url = `https://${api_url}/logistics/dispatch/export/excel/?start_date=${this.form.start_date}&end_date=${this.form.end_date}`;
      window.open(url, "_blank");
    },
    isCurrency(str) {
      // Regular expression to match a positive decimal number with two decimal places
      const regex = /^\d+(\.\d{2})?$/;

      // Use the test() method to check if the string matches the regular expression
      return regex.test(str);
    },
    openSetPosPriceModal(fuel) {
      this.selectedFuel = fuel;
      this.loadPosPrices(fuel.id);
      this.$bvModal.show("modal_select_price");
    },
    search() {
      this.getItems(1, this.form.start_date, this.form.end_date);
    },
    setNewPrice() {
      const fuel = this.selectedFuel;
      let promptPrice = prompt("Ingrese el precio deseado en formato 0.00");
      console.log(Utils.isValidCurrency(promptPrice));
      if (!Utils.isValidCurrency(promptPrice)) {
        alert("El precio ingresado no es válido");
        return false;
      }
      promptPrice = parseFloat(promptPrice);

      let prices = localStorage.getItem("pos_prices");
      prices = prices === null ? [] : JSON.parse(prices);

      prices.push({
        fuelId: fuel.id,
        fuelPrice: promptPrice.toFixed(2), // The user selected price
        fuelQuantity: promptPrice / parseFloat(fuel.sale_unit_price), // Equivalent quantity
      });
      this.posPrices = prices.filter((x) => x.fuelId === fuel.id);
      localStorage.setItem("pos_prices", JSON.stringify(prices));
    },
    getItems(page = 1) {
      let slug = `?page=${page}&page_size=${this.perPage}`;
      ApiService.get("fuel/dispenser", slug).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.count;
      });
    },
    loadPosPrices(fuelId) {
      let prices = localStorage.getItem("pos_prices");
      prices = prices === null ? [] : JSON.parse(prices);
      this.posPrices = prices.filter((x) => x.fuelId === fuelId);
    },
    handlePageChange(value) {
      this.page = value;
      this.getItems(value);
    },
  },
  mounted() {
    this.getPumps();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Surtidores" }]);
    const now = new Date().toISOString().slice(0, 10);
    this.form.start_date = now;
    this.form.end_date = now;
  },
};
</script>
