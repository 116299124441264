var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"size":"xl","id":"modal_select_price","title":"Seleccione o ingrese precios","ok-only":""}},[_c('div',{staticClass:"row"},[_vm._l((_vm.posPrices),function(item){return _c('div',{key:item.id,staticClass:"col-md-4"},[_c('div',{staticClass:"col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7",attrs:{"bis_skin_checked":"1"}},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-warning d-block my-2 text-warning"},[_vm._v(_vm._s(item.fuelQuantity.toFixed(3)))]),_c('a',{staticClass:"text-warning font-weight-bold",staticStyle:{"font-size":"40px"},attrs:{"href":"#"}},[_vm._v(" "+_vm._s(item.fuelPrice)+" ")])])])}),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7",staticStyle:{"cursor":"pointer"},attrs:{"bis_skin_checked":"1"},on:{"click":function($event){return _vm.setNewPrice()}}},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-warning d-block my-2"},[_c('b-icon',{staticStyle:{"color":"#ffa800 !important"},attrs:{"icon":"plus-circle"}})],1),_c('a',{staticClass:"text-warning font-weight-bold font-size-h6",attrs:{"href":"#"}},[_vm._v(" Definir venta rápida ")])])])],2)]),_c('portal',{attrs:{"to":"subheader_portal"}},[_c('router-link',{attrs:{"to":{ name: 'fuel_dispenser_create' }}},[_c('b-button',{attrs:{"variant":"success"}},[_c('b-icon',{attrs:{"icon":"plus","aria-hidden":"true"}}),_vm._v(" Nuevo surtidor")],1)],1)],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-7"},[_c('b-card',[_c('b-card-text',[_c('div',{staticClass:"row"},_vm._l((_vm.pumps),function(item){return _c('div',{key:item.id,staticClass:"col-md-4"},[_c('b-card',{staticClass:"text-center",style:({ backgroundColor: item.fuel.hex_color })},[_c('b-card-text',[_c('div',{staticClass:"font-weight-bolder font-size-h3",style:({
                      backgroundColor: item.fuel.hex_color,
                      textAlign: 'right',
                      color: _vm.getContrastColor(item.fuel.hex_color),
                    })},[_vm._v(" S/ "+_vm._s(item.fuel.sale_unit_price)+" ")]),_c('div',{style:({
                      fontSize: '120px',
                      fontWeight: 'bold',
                      textAlign: 'right',
                      paddingRight: '0px',
                      color: _vm.getContrastColor(item.fuel.hex_color),
                    })},[_vm._v(" "+_vm._s(item.fuel.internal_id)+" ")]),_c('div',{staticClass:"font-weight-bolder font-size-h1",style:({
                      backgroundColor: item.fuel.hex_color,
                      textAlign: 'right',
                      color: _vm.getContrastColor(item.fuel.hex_color),
                      cursor: 'pointer',
                    }),on:{"click":function($event){return _vm.openSetPosPriceModal(item.fuel)}}},[_c('b-icon',{attrs:{"icon":"cart3"}})],1)])],1)],1)}),0)])],1)],1),_c('div',{staticClass:"col-md-5"},[_c('b-card',[_c('b-card-text')],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }